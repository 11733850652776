.connect-two-title {
  font-weight: 800;
  font-size: 32px;
  line-height: 140%;
  letter-spacing: -1px;
}

.connect-two-text {
  font-family: "Poppins";
  opacity: 0.56;
  font-weight: 400;
  font-size: 20px;
  line-height: 180%;
}

.connect-two-img {
  height: 85%;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
  }
}
