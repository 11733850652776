.connect-section-one {
  background: #17C9BD;
  border-radius: 0px 200px 200px 200px;
  height: 100%;
  margin-top: -2px;
  padding: 200px 100px 160px 0px;
  margin-bottom: 30px;
  overflow-x: hidden !important;

  @media screen and (min-width: 1450px) {
    background-size: cover;
  }

  @media screen and (max-width: 991px) {
    background-size: cover;
    border-radius: 25px;
    padding: 49px 100px !important;
    margin-top: -30px;
  }

  @media screen and (max-width: 767px) {
    background-size: cover;
    margin-top: 30px;
    height: auto;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.connect-container{
  max-width: 1300px !important;
}

.connect-one-title {
  font-weight: 800;
  font-size: 32px;
  line-height: 140%;
  letter-spacing: -1px;
  margin-bottom: 32px;
  @media screen and (max-width:767px){
    font-size: 32px;
    margin-left: 26px;
    margin-right: 26px;
  }
}

.connect-one-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  line-height: 180%;
  @media screen and (max-width:767px){
    margin-left: 26px;
    margin-right: 26px;
  }
}

.connect-one-image {
  width: 653px;
  height: 555px;

  @media screen and (max-width: 1400px) {
    width: 100%;
    height: auto;
    padding-right: 20px;
  }
  @media screen and (max-width: 991px) {
    width: 100%;
    height: auto;
    padding-right: 0;
    padding-top: 50px;
  }
  @media screen and (max-width:767px){
    width: 110%;
    height: auto;
    margin-top: 32px;
    margin-left: -20px;
    padding-top: 0;
  }
}
