@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import "./HeroSection/HeroSection.scss";
@import "./AppOverview//AppOverview.scss";
@import "./SessionsClients/SessionsClients.scss";
@import "./AppAdvantages/AppAdvantages.scss";
@import "./SliderSection/SliderSection.scss";
@import "./ConnectSectionOne/ConnectSectionOne.scss";
@import "./ConnectSectionTwo/ConnectSectionTwo.scss";
@import "./Testimonials/Testimonials.scss";
@import "./FAQSection/FAQSection.scss";
@import "./Newsletter/Newsletter.scss";
@import "./Footer/Footer.scss";

// custom variables
$enable-gradients: true;

.gradient-primary {
  background-image: linear-gradient(269.57deg, #17c9bd 10.06%, #567dcf 80.58%);
}
$theme-colors: (
  "primary": #fff,
  "secondary": #353535,
  "regular": #7e7e7e,
  "light": #7d7987,
  "lighter": #9b9696,
  "black": #000000,
  "dark": #18191f,
  "green": #17c9bd,
  "darker": #121127,
  "blue": #577ed0,
  "grey": #f8f8f8,
  "night": #263238,
  "dim": #474a57,
  "basic": #000000,
);

$font-family: "Montserrat", sans-serif !important;
$body-bg: #fff;
$border-radius: 20px;
$input-focus-box-shadow: none !important;

body {
  font-family: $font-family;
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.btn-gradient {
  width: 206px;
  height: 58px;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.02em;
  box-shadow: 0px 4px 10px 1px rgba(183, 183, 183, 0.25) !important;
  border-radius: 100px !important;
  border: none !important;
}

.horizontal-line {
  height: 10px !important;
  width: 52px !important;
  border-radius: 20px;

  @media screen and (max-width:767px){
    width: 52px !important;
  }
}

//import the functions & variables
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

//import bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";
