.hero-section {
  max-width: 1200px !important;
  height: auto;
  margin-bottom: 158px;

  @media screen and (max-width: 991px) {
    padding: 14px 26px !important;
  }
  @media screen and (max-width: 767px) {
    height: auto;
    margin-bottom: 0;
    padding: 12px 26px;
    flex-direction: column !important;
  }
}

.info-container{
  width: 750px;
  @media screen and (max-width:1200px){
    width: auto;
  }
}
.nav-styling {
  font-size: 16px !important;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em !important;
  margin-top: 36px;
  z-index: 3 !important;
  cursor: pointer;

  @media screen and (max-width: 991px) {
    margin-top: 11px;
  }
}

.navbar{
  background-image: none !important;
}

.nav-container{
  padding: 0 !important;
}

.md-mt-1 {
  margin-top: -50px !important;
  @media screen and (max-width: 575px) {
    margin-top: 200px !important;
  }
}

.hero-intro {
  margin-top: 228px;
  @media screen and (max-width:991px){
    margin-top: 100px;
  }
}

.intro-title {
  font-size: 50px !important;
  line-height: 74px !important;
  font-weight: 800 !important;
  margin-bottom: 0 !important;
  letter-spacing: -0.06em !important;

  @media screen and (max-width: 991px) {
    font-weight: 800 !important;
    font-size: 40px !important;
    line-height: 74px !important;
  }
}

.hero-p{
  margin-top: 50px;
  font-weight: 500 ;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.05em;
  color: #7E7E7E;
  width: 600px;

  @media screen and (max-width:991px){
    width: 400px;
  }
}

.intro-text {
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.05em;
}

.hero-bg-image {
  position: absolute;
  right: 0;
  top: 0;
  width: 40%;
  height: auto;
  z-index: -1 !important;

  @media screen and (max-width: 991px) {
    top: 0;
    height: 60%;
    width: 50%;
    overflow: hidden !important;
  }

  @media screen and (max-width: 575px) {
    top: -18%;
    right: 0;
    height: 90%;
    width: 70%;
    overflow: hidden !important;
  }
}

.hero-image {
  width: 312.1px;
  height: 643px;
  margin-left: 150px;
  margin-top: 190px;
  border-radius: 32px;
  z-index: 999 !important;
  box-shadow: 40px 60px 80px rgba(68, 111, 196, 0.15), inset -2px -10px 20px rgba(147, 185, 221, 0.4), inset 10px 2px 20px #FFFFFF;

  @media screen and (max-width:1200px){
    margin-left: 10px;
  }
  @media screen and (max-width:991px){
    width: 196px;
    height: auto;
    margin-top: 145px;
    margin-left: 100px;
  }
  @media screen and (max-width:767px){
    width: 196px;
    height: 100%;
    margin-top: 45px;
    margin-left: 0;
  }

}
