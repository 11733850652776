.slider-section {
  background-image: url("../img/white-bg.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  padding-bottom: 90px;
  margin-top: 90px;

  @media screen and (max-width:991px) {
    margin-top: 0;
  }
}

.slider-container{
  max-width: 1400px !important;
  @media screen and (max-width:1400px){
    width: auto;
    margin-top: 67px;
  }
}

.carousel-control-prev {
  background-image: none !important;
}

.carousel-control-next {
  background-image: none !important;
}

.carousel-caption {
  position: static !important;
}

.carousel-control-prev-icon {
  background-image: url("../img/slider-prev.svg") !important;
}

.carousel-control-next-icon {
  background-image: url("../img/slider-next.svg") !important;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: none !important;
  height: 48px !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 84px !important;
}

.carousel-control-prev {
  opacity: 1 !important;
  display: inline-block !important;
  width: auto !important;
  position: absolute !important;
  left: 9% !important;
  right: 0 !important;
  top: 70% !important;
  width: 100px !important;

  @media screen and (max-width: 1200px) {
    left: 12% !important;
    top: 80% !important;
  }

  @media screen and (max-width: 991px) {
    left: 10% !important;
    top: 78% !important;
  }

  @media screen and (max-width: 767px) {
    top: 100% !important;
  }
}

.carousel-control-next {
  opacity: 1 !important;
  display: inline-block !important;
  width: auto !important;
  position: absolute !important;
  left: 16% !important;
  right: 0 !important;
  top: 70% !important;
  width: 100px !important;

  @media screen and (max-width: 1200px) {
    left: 22% !important;
    top: 80% !important;
  }

  @media screen and (max-width: 991px) {
    top: 78% !important;
    left: 22% !important;
  }

  @media screen and (max-width: 767px) {
    top: 100% !important;
    left: 180px !important;
  }
  @media screen and (max-width: 575px) {
    top: 100% !important;
    left: 160px !important;
  }
}

.slider-section .carousel-indicators {
  display: none !important;
  cursor: default !important;
  pointer-events: none !important;
}

.slider-img {
  float: right;
  height: 575px !important;
  width: auto;
  margin-bottom: 30px !important;

  @media screen and (max-width: 1200px) {
    height: 450px !important;
    width: auto !important;
  }

  @media screen and (max-width: 991px) {
    height: 300px !important;
    width: auto !important;
  }

  @media screen and (max-width: 767px) {
    height: 250px !important;
    width: auto !important;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.slide-title {
  font-weight: 800;
  font-size: 40px;
  line-height: 140%;
  letter-spacing: -1px;

  @media screen and (max-width: 991px) {
    font-size: 22px;
  }
  @media screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -1px;
    height: 70px;
  }
}

.slide-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  height: 200px;

  @media screen and (max-width: 767px) {
    height: 120px;
    margin-top: 30px;
  }
}
