.advantages-container {
  height: auto;
}

.advantages-col {
  position: relative;
}

.hero-im {
  width: 312.1px;
  height: 643px;
  position: absolute;
  left: 24%;
  border-radius: 32px;
  z-index: 9;

  @media screen and (min-width: 992px) {
    box-shadow: 10px 2px 20px 0px rgba(255, 255, 255, 1) inset;
    box-shadow: -2px -10px 20px 0px rgba(147, 185, 221, 0.4) inset;
    box-shadow: 40px 60px 80px 0px rgba(68, 111, 195, 0.15);
    box-shadow: 12px 16px 80px 0px rgba(0, 0, 0, 0.12);
  }

  @media screen and (max-width: 1200px) {
    left: 10%;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    height: auto;
    margin-top: 120px;
    margin-bottom: auto;
    left: 0%;
  }

  @media screen and (max-width: 575px) {
    width: auto;
    height: auto;    left: 0%;
  }
}

.app-card {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1 1 auto;
}

.app-icon {
  width: 72px;

  @media screen and (max-width: 575px) {
    align-self: flex-start;
  }
}

.advantages-title {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 36px !important;

  @media screen and (max-width: 575px) {
    line-height: 32px !important;
  }
}

.advantages-text {
  width: 428px;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  @media screen and (max-width:991px){
    width: auto;
  }
}
