.overview-container{
 @media screen and (max-width:991px){
  margin: 0 !important;
  padding: 0 !important;
  }
}
.app-overview {
  width: 1200px;
  max-width: 1300px !important;
  height: auto;
  margin-bottom: 150px;

  @media screen and (max-width:1400px){
    width: auto;
  }

  @media screen and (max-width:767px){
    flex-direction: column;
    margin-bottom: 0;
    padding: 12px 26px;
  }
}

.overview-info-container{
  width: 460px;
  @media screen and (max-width:991px){
  width: 100%;
  }
}
.overview-title {
  font-weight: 800;
  font-size: 40px;
  line-height: 74px;
  letter-spacing: -0.06em;
  @media screen and (max-width:767px){
    margin-top: 50px;
    margin-bottom: 3px;
    }
}

.overview-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.05em;
  height: auto;

}

.app-overview-img {
  width: 613px;
  height: 463px;
  margin-right: 120px;
  z-index: 9;

  @media screen and (max-width:1200px){
    width: 300px;
    height: 100%;
  }
  @media screen and (max-width:767px){
    width: 300px;
    margin-top: 30px;
    margin-bottom: 80px;
  }
}
