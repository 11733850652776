.footer-section .navbar {
  background-image: none !important;
}

.footer-styling {
  font-family: "Inter";
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 26px !important;
  cursor: pointer;

  @media screen and (max-width: 360px) {
    margin: 0 !important;
  }
}

.footer-line {
  border-top: #fff solid 1px;
  opacity: 0.2;
}

.footer-credits {
  font-family: "Inter";
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.footer-icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #18191f;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px !important;
}

.footer-icon:hover {
  background-color: #18191f !important;
  color: #fff !important;
}

.tac-title {
  font-weight: 800;
  font-size: 36px;
  line-height: 51px;
  letter-spacing: -0.06em;

  @media screen and (max-width: 767px) {
    font-size: 26px;
  }
}

.tac-subtitle {
  font-weight: 800;
  font-size: 26px;
  line-height: 51px;
  letter-spacing: -0.06em;

  @media screen and (max-width: 767px) {
    font-size: 22px;
  }
}

.tac-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.06em;
}

.modal-header .btn-close {
  margin-right: 20px !important;
  background-image: url("../img/modal-btn.svg");
  background-size: cover;
  padding: 0;
  background-repeat: no-repeat;
  border-radius: 0;
  opacity: 1;
}

.footer-credits{
  justify-content: center;
  display: flex;
}

.footer-credits-container{
  display: flex;
  max-width: 1200px !important;
  border-top: 1px solid #333333;
  padding-top: 25px;
  @media screen and (max-width:991px){
    flex-direction: column;
  }
}
