.newsletter-section-container{
  margin-bottom: 130px;
}

.newsletter-bg {
  background-color: #f8f8f8;
  height: 320px;

  @media screen and (max-width: 991px) {
    height: 480px;
  }

  @media screen and (max-width: 767px) {
    height: 520px;
  }

  @media screen and (max-width: 370px) {
    height: 600px;
  }
}

.newsletter-col {
  position: relative;
}

.newsletter-doodle-1 {
  position: absolute;
  height: 40%;
  left: -6%;
  top: -16%;

  @media screen and (max-width: 991px) {
    height: 30%;
    left: -10%;
    top: -10%;
  }
  @media screen and (max-width: 767px) {
    height: 20%;
    left: -8%;
    top: -8%;
  }

  @media screen and (max-width: 370px) {
    left: -15%;
  }
}

.newsletter-doodle-2 {
  position: absolute;
  right: -4%;
  bottom: -14%;

  @media screen and (max-width: 370px) {
    bottom: -10%;
  }
}

.newsletter-doodle-3 {
  position: absolute;
  top: 0;
  left: 30%;

  @media screen and (max-width: 991px) {
    left: 80%;
  }

  @media screen and (max-width: 767px) {
    left: 50%;
  }
}

.newsletter-doodle-mob {
  position: absolute;
  right: 0;
  top: 30%;
}

.newsletter-title {
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.06em;
}

.newsletter-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.05em;
}

.newsletter-form {
  background-color: #fff;
  height: 58px;
  width: 200px;

  @media screen and (max-width: 991px) {
    background-color: transparent !important;
  }
}

.newsletter-placeholder {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  @media screen and (max-width: 991px) {
    height: 58px;
  }
}

.newsletter-button {
  @media screen and (min-width: 1400px) {
    width: 300px !important;
    position: absolute;
    right: 5%;
  }
  @media screen and (min-width: 991px) {
    width: 250px !important;
  }

  @media screen and (max-width: 1200px) {
    width: 80% !important;
  }
  @media screen and (max-width: 991px) {
    width: 100% !important;
    margin-top: 30px;
  }
}

.newsletter-group {
  background-color: transparent;
}

.newsletter-form-row {
  background-color: #fff;
  height: 58px;
}

.newsletter-feedback {
  height: 30px;
}

.was-validated .form-control:invalid {
  background-image: none !important;
  color: #fff;
  border-color: #fff;
}

.newsletter-feedback {
  height: 0px;
}
