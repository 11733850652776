.testimonials-section {
  height: auto;
  position: relative;
  background-color: #577ed0;
}

.testimonials-img {
  position: absolute;
  height: 20%;
  opacity: 0.4;
  top: 10%;

  @media screen and (min-width: 767px) {
    right: 10%;
  }

  @media screen and (max-width: 991px) {
    height: 10%;
    top: 14%;
  }
  @media screen and (max-width: 757px) {
    height: 10%;
    top: 22%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.testimonials-section .carousel-indicators {
  top: 100%;
}

.carousel-indicators [data-bs-target] {
  cursor: not-allowed !important;
  pointer-events: none !important;

  @media screen and (max-width: 767px) {
    cursor: pointer !important;
    pointer-events: auto !important;
  }
}

.testimonials-section .carousel-control-prev-icon {
  display: none !important;
}

.testimonials-section .carousel-control-next-icon {
  display: none !important;
}

.carousel-indicators button {
  border-radius: 50% !important;
  width: 12px !important;
  height: 12px !important;
  background-color: #fff !important;
}

.testimonials-section .carousel-item.active {
  display: flex;
}

.testimonials-card {
  height: 300px;
  background-color: transparent !important;
  background-image: url("../img/testimonials-card-pc.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  border: none !important;

  @media screen and (max-width: 991px) {
    background-image: url("../img/testimonials-card-mob.svg");
    height: 260px;
  }

  @media screen and (max-width: 767px) {
    background-image: url("../img/testimonials-card-mob.svg");
    height: 240px;
  }

  @media screen and (max-width: 767px) {
    background-image: url("../img/testimonials-card-mob.svg");
    height: 340px;
  }
}

.testimonials-client-img {
  height: 56px;
  width: 56px;
}

.testimonials-title {
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 28px !important;
}

.testimonials-text {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.testimonials-client {
  font-family: "Inter";
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

.testimonials-carousel-row {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 767px) {
    padding-top: 80px;
  }
}

.testimonials-carousel{
  max-width: 1200px !important;
  display: flex;
  justify-content: center;
}