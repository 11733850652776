.sessions-clients {
  background-image: url("../img/clients-ellipse.svg");
  background-repeat: no-repeat;
  background-position: 100% 0%;

  @media screen and (max-width: 575px) {
    background-position: -200% 28%;
  }
}

.c-container{
  @media screen and (max-width: 1200px) {
    padding: 14px 26px !important;
  }
}

.clients-title {
  font-weight: 800;
  font-size: 40px;
  line-height: 74px;
  letter-spacing: -0.06em;

  @media screen and(max-width:767px) {
    margin: 20px;
    margin-bottom: 7px;
  }

  @media screen and (max-width: 575px) {
    font-size: 40px;
    line-height: 44px;
  }
}

.cards-container{
  margin-top: 45px;
  @media screen and (max-width:767px){
    flex-direction: column;
  }
}
.clients-card {
  box-shadow: 10px 40px 50px rgba(229, 233, 246, 0.4);
  border-radius: 20px;
  position: relative;
  height: auto;
  width: 575px;
  background-color: #fff;
  padding-bottom: 75px;
  padding-top: 10px;

  @media screen and (max-width: 991px) {
    width: auto;
    height: auto;
    padding: 35px 35px;
  }
}

.card-two{
  margin-left: 52px;

  @media screen and (max-width:767px){
    margin-left: 0;
    margin-top: 55px;
    margin-bottom: 37px;
  }

}
.clients-card-title {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 56px !important;
  padding-left: 60px;
  @media screen and (max-width: 767px) {
    padding-left: 0px;
  }
}

.clients-card-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  padding-left: 60px;

  @media screen and (max-width: 767px) {
    bottom: auto !important;
    padding-left: 0px;
  }
}

.clients-card-image {
  height: 140px;
  width: auto;
  padding-left: 32px;
  padding-top: 45px;
  @media screen and (max-width: 767px) {
    width: 140px;
    height: 121px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 0;  }
}

.clients-p {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.05em;

}

.disc-container{
  position: relative;
}

.small-circle {
  position: absolute;
  width: 100px;
  height: 100px;
  right: 42px;
  top: 30px;
}

.discover-container{
  max-width: 1200px !important;
  background: #17C9BD;
  border-radius: 20px;
  width: 1200px;
  height: 236px;
  margin-top: 135px;
  margin-bottom: 92px;
  padding: 33px 60px;
  @media screen and (max-width:991px){
    height: auto;
    width: 100%;
    margin-top: 135px;
    margin-bottom: 0px;
    padding: 33px 26px !important;

  }
  @media screen and (max-width:767px){
    margin-top: 0px;
  }
}
.discover-info{
  border-radius: 20px;
  @media screen and (max-width:991px){
    height: auto;
    width: auto;
  }
}

.discover-title{
  font-weight: 800;
  font-size: 40px;
  line-height: 74px;
  color: #FFFFFF;
@media screen and (max-width:991px){
  line-height: 44px;
}
}
.discover-p{
  width: 650px;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.05em;
  @media screen and (max-width:991px){
    height: auto;
    width: auto;
  }
}