.faq-row {
  position: relative;
}

.faq-question {
  font-family: "Inter";
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.faq-answer {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

.faq-need-help {
  font-family: "Inter";
  font-weight: 800;
  font-size: 40px;
  line-height: 54px;
}

.faq-need-help-p {
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}
